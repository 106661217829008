import Factory_img from "../assets/Factory_img.png";
import gc_logo from "../assets/gc_logo.png";
// import logo from "../assets/logo.jpeg";
import logo from "../assets/logo.png";

import Certificate1 from "../assets/Certificate1.png";
import Certificate2 from "../assets/Certificate2.png";

import gconveyors from "../assets/gconveyors.png";
import bg_img from "../assets/bg_img.jpeg";
import quality_assurance from "../assets/quality_assurance.png";
import customer_satisfaction from "../assets/customer_satisfaction.png";
import mutual_growth from "../assets/mutual_growth.png";
import teamwork from "../assets/teamwork.png";
import respect_all from "../assets/respect_all.png";
import agriculture from "../assets/agriculture.png";
import sugarcane from "../assets/sugarcane.png";
import escalator from "../assets/escalator.png";
import otherindustry from "../assets/otherindustry.png";
import power_plant from "../assets/power_plant.png";
import tobacco from "../assets/tobacco.png";
import Mining from "../assets/Mining.png";
import Fertilizer_Plants from "../assets/Fertilizer_Plants.png";
import textile from "../assets/textile.png";
import Steel_Plants from "../assets/Steel_Plants.png";
import Cement_Plants from "../assets/Cement_Plants.png";
import chain_img_bg from "../assets/chain_img_bg.jpeg";
import chain_img from "../assets/About_Img_2.jpg";
import global from "../assets/global.png";
import india_location from "../assets/india_location.png";
import Industries_Serving from "../assets/Industries_Serving.png";
import Team_Members from "../assets/Team_Members.png";
import Happy_Customers from "../assets/Happy_Customers.png";
import Manufacturing_Capacity from "../assets/Manufacturing_Capacity.png";
import Manufacturing_Unitof from "../assets/Manufacturing_Unitof.png";
import experience from "../assets/experience.png";
// import RollerChains_img from "../assets/RollerChains_img.png";
import Chainimg from "../assets/Chainimg.jpeg";
// import RollerChain1 from "../assets/RollerChain1.png";
// import RollerChain2 from "../assets/RollerChain2.png";
// import RollerChain3 from "../assets/RollerChain3.png";
// import RollerChain4 from "../assets/RollerChain4.png";
import catalogue from "../assets/catalogue.pdf";
import cat_roller from "../assets/GC_Catagories/cat_roller.jpg";
import cat_bushed from "../assets/GC_Catagories/cat_bushed.jpg";
import Flow_Conveyor from "../assets/GC_Catagories/Flow_Conveyor.jpg";
import cat_bush from "../assets/GC_Catagories/cat_bush.jpg";
import Hollow_Bearing_Pin_Chain from "../assets/GC_Catagories/Hollow_Bearing_Pin_Chain.jpg";
import Bottling_Chains from "../assets/GC_Catagories/Bottling_Chains.jpg";
import Forged_Chains from "../assets/GC_Catagories/Forged_Chains.jpg";
import core_purpose_head from "../assets/core_purpose_head_img.png";
import core_img1 from "../assets/core_img1.png";
import core_img2 from "../assets/core_img2.png";
import core_img3 from "../assets/core_img3.png";
import core_img4 from "../assets/core_img4.png";
import core_img5 from "../assets/core_img5.png";
import home_slider_img1 from "../assets/home_slider_img1.jpg";
import home_slider_img2 from "../assets/home_slider_img2.webp";
import home_slider_img3 from "../assets/home_slider_img3.jpg";
import ind1 from "../assets/GC_Industries/ind1.jpg";
import ind2 from "../assets/GC_Industries/ind2.jpg";
import ind3 from "../assets/GC_Industries/ind3.jpg";
import ind4 from "../assets/GC_Industries/ind4.jpg";
import ind5 from "../assets/GC_Industries/ind5.jpg";
import ind6 from "../assets/GC_Industries/ind6.jpg";
import ind7 from "../assets/GC_Industries/ind7.jpg";
import ind8 from "../assets/GC_Industries/ind8.jpg";
import ind9 from "../assets/GC_Industries/ind9.jpg";
import ind10 from "../assets/GC_Industries/ind10.jpg";
import ind11 from "../assets/GC_Industries/ind11.jpg";
import ind12 from "../assets/GC_Industries/ind12.jpg";

import SmallRoller from "../assets/SmallRoller.png";
import FlangedRoller from "../assets/FlangedRoller.png";
import BushedChain from "../assets/BushedChain.jpg";
import FlowConveyor from "../assets/FlowConveyor.png";
import WeldedBush from "../assets/WeldedBush.png";
import HollowPin from "../assets/HollowPin.png";
import SolidPin from "../assets/SolidPin.png";
import RivettedBottlingChain from "../assets/RivettedBottlingChain.png";
import DetachableBottlingChain from "../assets/DetachableBottlingChain.png";
import Rivetless from "../assets/Rivetless.png";
import Drag from "../assets/Drag.png";
import About_head from "../assets/About_head.png";

import SmallRollerChain_Image from "../assets/SmallRollerChain_Image.jpg";
import SmallRollerChain_Graph from "../assets/SmallRollerChain_Graph.jpg";
import FlangedChain_Image from "../assets/FlangedChain_Image.jpg";
import FlangedChain_Graph from "../assets/FlangedChain_Graph.jpg";
import BushedChain_Image from "../assets/BushedChain_Image.png";
import Bushed_Chain_Image from "../assets/Bushed_Chain_Image.jpg";
import BushedChain_Graph from "../assets/BushedChain_Graph.jpg";
import FlowConveyorChain_Image from "../assets/FlowConveyorChain_Image.jpg";
import FlowConveyorChain_Graph from "../assets/FlowConveyorChain_Graph.jpg";
import WeldedBushChain_Graph from "../assets/WeldedBushChain_Graph.jpg";
import WeldedBushChain_Img from "../assets/WeldedBushChain_Img.jpg";
import HollowBearingPinChain_Img from "../assets/HollowBearingPinChain_Img.jpg";
import HollowBearingPinChain_Graph from "../assets/HollowBearingPinChain_Graph.jpg";
import SolidBearingPinChain_Image from "../assets/SolidBearingPinChain_Image.jpg";
import SolidBearingPinChain_Graph from "../assets/SolidBearingPinChain_Graph.jpg";
import RivetTypeBottlingChain_Image from "../assets/RivetTypeBottlingChain_Image.jpg";
import RivetTypeBottlingChain_Graph from "../assets/RivetTypeBottlingChain_Graph.jpg";
import DetachableBottlingChain_Image from "../assets/DetachableBottlingChain_Image.jpg";
import DetachableBottlingChain_Graph from "../assets/DetachableBottlingChain_Graph.jpg";
import ForgedRivetlessChain_Image from "../assets/ForgedRivetlessChain_Image.jpg";
import ForgedRivetlessChain_Graph from "../assets/ForgedRivetlessChain_Graph.jpg";
import DragChain_Image from "../assets/DragChain_Image.jpg";
import DragChain_Graph from "../assets/DragChain_Graph.jpg";

import ProductRange from "../assets/ProductRange.jpg";
import factory_gray from "../assets/factory_gray.jpg";
import chain_mix from "../assets/chain_mix.jpg";
export default {
  catalogue,
  factory_gray,
  chain_mix,
  ProductRange,
  Certificate1,
  Certificate2,
  bg_img,
  About_head,
  Factory_img,
  gc_logo,
  logo,
  gconveyors,
  quality_assurance,
  customer_satisfaction,
  mutual_growth,
  teamwork,
  respect_all,
  agriculture,
  sugarcane,
  escalator,
  otherindustry,
  power_plant,
  tobacco,
  Mining,
  Fertilizer_Plants,
  textile,
  Steel_Plants,
  Cement_Plants,
  chain_img_bg,
  chain_img,
  global,
  india_location,
  Industries_Serving,
  Team_Members,
  Happy_Customers,
  Manufacturing_Capacity,
  Manufacturing_Unitof,
  experience,
  Chainimg,
  cat_roller,
  cat_bushed,
  Flow_Conveyor,
  cat_bush,
  Hollow_Bearing_Pin_Chain,
  Bottling_Chains,
  Forged_Chains,
  core_purpose_head,
  core_img1,
  core_img2,
  core_img3,
  core_img4,
  core_img5,
  home_slider_img1,
  home_slider_img2,
  home_slider_img3,
  ind1,
  ind2,
  ind3,
  ind4,
  ind5,
  ind6,
  ind7,
  ind8,
  ind9,
  ind10,
  ind11,
  ind12,
  SmallRoller,
  FlangedRoller,
  BushedChain,
  FlowConveyor,
  WeldedBush,
  HollowPin,
  SolidPin,
  RivettedBottlingChain,
  DetachableBottlingChain,
  Rivetless,
  Drag,

  SmallRollerChain_Image,
  SmallRollerChain_Graph,
  BushedChain_Image,
  Bushed_Chain_Image,
  BushedChain_Graph,
  FlowConveyorChain_Image,
  FlowConveyorChain_Graph,
  WeldedBushChain_Graph,
  WeldedBushChain_Img,
  HollowBearingPinChain_Graph,
  HollowBearingPinChain_Img,
  SolidBearingPinChain_Image,
  SolidBearingPinChain_Graph,
  RivetTypeBottlingChain_Image,
  RivetTypeBottlingChain_Graph,
  DetachableBottlingChain_Image,
  DetachableBottlingChain_Graph,
  ForgedRivetlessChain_Image,
  ForgedRivetlessChain_Graph,
  FlangedChain_Image,
  FlangedChain_Graph,
  DragChain_Image,
  DragChain_Graph,
};
